<template>
  <v-container>
    <v-card class="pa-5">
      <p v-if="done">
        「{{ address }}」のメルマガ登録を解除しました。
      </p>
    </v-card>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      address: '',
      done: false,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    this.address = this.$route.params.address
    this.unsubscribe()
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    unsubscribe() {
      this.apiTool.apiReqWithData('DELETE', 'email/delete/' + this.address).then(() => {
        this.done = true
      })
      .catch(error => {
        AlertApiError(error)
      })
    },
  }
}
</script>

<style scoped>
</style>
